import * as React from 'react'
import {useContext, useEffect, useState} from "react";
import GlobalContext from "../../context/GlobalContext";
import {GatsbyImage} from "gatsby-plugin-image";
import {graphql, Link, useStaticQuery} from "gatsby";

export default function ProdottiList() {
    const gContext = useContext(GlobalContext);
    const allImagesQuery = graphql`
            query {
              allFile(
                filter: {
                    relativeDirectory: { regex: "/(prodotti)/" },
                    extension: {ne: "svg"}
                }
              ) {
                edges {
                  node {
                    base
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]      
                        placeholder: NONE         
                        quality: 100
                      )
                    }
                  }
                }
              }
            }
          `
    const {
        allFile: {edges: images},
    } = useStaticQuery(allImagesQuery)
    const [products, setProducts] = useState({});
    useEffect(() => {
        setProducts({
            prodotti: [
                {href: 'tendedasole', name: 'Tende da sole', image: 'placeholder-tendedasole.jpg'},
                {href: 'pergole', name: 'Pergole', image: 'placeholder-pergole.jpg'},
                {href: 'pergolebioclimatiche', name: 'Pergole bioclimatiche', image: 'placeholder-bioclimatiche.jpg'},
                // {href: 'veleombreggianti', name: 'Vele ombreggianti', image: 'placeholder-veleombreggianti.jpg'},
                {href: 'rivestimenti', name: 'Rivestimenti', image: 'placeholder-rivestimenti.jpg'},
                {href: 'arredamentointerni', name: 'Arredamento di interni', image: 'placeholder-interni.jpg'},
            ],
        })
    }, [gContext])
    return (
        <div className="relative w-full bg-gray-50 py-16">
            <div className="max-w-[1400px] mx-auto px-4 sm:px-6 lg:px-8 text-justify sm:text-center">
                <h2 className="title-section text-center mb-4 sm:mb-10">{gContext.t('Tutti i prodotti')}</h2>
                <p>{gContext.t('La luce più dolce, la temperatura ideale, l’eleganza senza tempo di uno spazio plasmato dai progetti, dai desideri e dai sogni. Dietro una tenda da sole, sotto una pergola, la vita si espande per diventare più comoda e bella.')}</p>
                <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-16 xl:gap-20">
                    {(products?.prodotti || []).map((item) => (
                        <Link to={gContext.url(item.href)} key={item.name} className="w-full flex flex-col items-center">
                            <h3 className="font-bold uppercase text-lg lg:text-xl xl:text-2xl mb-1">{gContext.t(item.name)}</h3>
                            <div className="relative w-full overflow-hidden rounded-xl mx-auto h-[215px] flex items-center justify-center">
                                <div className="absolute inset-0">
                                    <GatsbyImage image={gContext.getImageFromName(images, item.image)}
                                                 alt={item.image}
                                                 loading="eager"
                                                 objectFit="cover"
                                                 objectPosition="50% 50%"
                                                 className="h-full w-full object-cover" />
                                    <div className="absolute inset-0 bg-black/10 mix-blend-multiply"></div>
                                </div>
                                <button type="button" className="opacity-0 hover:opacity-100 transition duration-300 ease-in-out relative px-4 py-20 sm:px-6 sm:py-24 lg:py-32 lg:px-8 flex items-center justify-center w-full">
                                    <div className="inline-flex items-center justify-center px-4 py-3 rounded-lg bg-black/50 text-white uppercase font-bold text-base min-h-[50px] w-full max-w-[200px]">{gContext.t('Scopri')}</div>
                                </button>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    )
}
