import * as React from 'react'
import {useContext, useEffect, useState} from "react";
import GlobalContext from "../../context/GlobalContext";
import {Autoplay, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import {GatsbyImage} from "gatsby-plugin-image";
import {graphql, Link, useStaticQuery} from "gatsby";

export default function News() {
    const gContext = useContext(GlobalContext);
    const allImagesQuery = graphql`
            query {
              allFile(
                filter: {
                    relativeDirectory: { regex: "/(news)/" },
                    extension: {ne: "svg"}
                }
              ) {
                edges {
                  node {
                    base
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]       
                        placeholder: NONE        
                        quality: 100
                      )
                    }
                  }
                }
              }
            }
          `
    const {
        allFile: {edges: images},
    } = useStaticQuery(allImagesQuery)
    const [news, setNews] = useState({});
    useEffect(() => {
        setNews({
            new: [
                {
                    id: 0,
                    href: 'news',
                    name: 'GREEN LIFE: UN BRAND DI GAMMA',
                    image: 'greenlife-news.jpg',
                    prev: 'Gamma Tendaggi e Tessuti  ha lanciato un nuovo brand: Green Life, nato dal riciclo. È un progetto tessile sostenibile che prevede prodotti ad impatto ambientale zero, che nascono dal recupero della plastica dal recupero dei tessuti.'
                },
                {
                    id: 1,
                    href: 'news',
                    name: 'ENERGY: NUOVA PERGOLA A RISPARMIO ENERGETICO',
                    image: 'energy.jpg',
                    prev: 'Dotate di pannelli fotovoltaici per rendere la luce del sole una fonte di produzione energetica. La possibilità di orientare liberamente le lame frangisole risulta un perfetto ausilio per una migliore orientazione dei moduli fotovoltaici rispetto ai raggi solari.'
                },
                {
                    id: 2,
                    href: 'news',
                    name: 'VELVET: PERGOLA BIOCLIMATICA AD IMPACCHETTAMENTO',
                    image: 'GIBUS_VELVET_PLUS_4.jpg',
                    prev: 'Adesso anche le pergole bioclimatiche possono essere impacchettabili: Le lame si impacchettano con movimento indipendente rispetto alla rotazione, permettendo pieno controllo e liberando lo sguardo verso il cielo.'
                },
                {
                    id: 3,
                    href: 'news',
                    name: 'Pergole bioclimatiche',
                    image: 'news_pergola_bioclimatica.jpg',
                    prev: 'Pergola a lame orientabili motorizzata per ottenere il massimo comfort e perfetta tenuta in caso di pioggia perché le lame sono state progettate per impermeabilizzare al meglio e consentire il deflusso dell\'acqua solo negli appositi pluviali di scarico integrati nelle colonne della struttura. Possibilità di illuminazione integrata con led.'
                },
                {
                    id: 4,
                    href: 'news',
                    name: 'Tenda a bracci Cassonata',
                    image: 'news_cassonata.jpg',
                    prev: 'Le tende a bracci cassonate sono progettate per ospitare il telo all\'interno di un cassonetto di alluminio. Quando la tenda si chiude, il frontale si incastra perfettamente con il cassonetto garantendo la massima protezione dagli agenti atmosferici e un\'estetica minimale e pulita.'
                },
                {
                    id: 5,
                    href: 'news',
                    name: 'Tende in trazione',
                    image: 'news_tenda_in_trazione.jpg',
                    prev: 'Nuovo sistema per tende in trazione senza frangivento. Vantaggi: facili da installare, pratiche da aprire e chiudere con le guide e con il sistema motorizzato di serie, protetto insieme al telo dal cassonetto e senza alcun "sfarfallamento" grazie alle molle a gas che tengono il telo teso.'
                },
                {
                    id: 6,
                    href: 'news',
                    name: 'Nuovi arrivi! Cotoni stampati!',
                    image: 'news_cotoni_colorati.jpg',
                    prev: 'Cotoni stampati, colorati, festosi per rinnovare la camerina, il soggiorno, la zona relax. Vieni a visitare il nostro showroom per poterli vedere e toccare dal vivo.'
                },
            ],
        })
    }, [gContext])
    return (
        <div className="relative w-full parallax py-60">
            <div className="relative w-full bg-gray-50 shadow-mine py-16">
                <div className="max-w-[1400px] mx-auto px-4 sm:px-6 lg:px-8 text-center">
                    <div className="w-full flex items-center justify-center lg:justify-between lg:space-x-2">
                        <h2 className="title-section text-center lg:text-left">{gContext.t('News tappezzeria orsilli')}</h2>
                        <div className="hidden lg:flex items-center justify-center">
                            <Link to={gContext.url('news')} className="transparent-button">{gContext.t('Tutte le news')}</Link>
                        </div>
                    </div>
                    <div className="w-full py-6">
                        <Swiper
                            className="swiper-news"
                            loop
                            autoplay={{
                                delay: 8000,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Autoplay, Pagination]}
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 50,
                                },
                                900: {
                                    slidesPerView: 2,
                                    spaceBetween: 80,
                                },
                                1300: {
                                    slidesPerView: 3,
                                    spaceBetween: 100,
                                },
                            }}
                        >
                            {(news?.new || []).map((item) => (
                                <SwiperSlide key={item.id}>
                                    <div key={item.name} className="text-left w-full max-w-[450px] mx-auto">
                                        <h3 className="uppercase text-lg lg:text-[20px] mb-1 h-[56px] leading-tight flex items-end">{gContext.t(item.name)}</h3>
                                        <div className="relative w-full overflow-hidden mx-auto h-[230px] flex items-center justify-center">
                                            <div className="absolute inset-0 rounded-xl">
                                                <GatsbyImage image={gContext.getImageFromName(images, item.image)}
                                                             alt={item.image}
                                                             loading="eager"
                                                             objectFit="cover"
                                                             objectPosition="50% 50%"
                                                             className="h-full w-full object-cover rounded-xl" />
                                                <div className="absolute inset-0 bg-black/10 mix-blend-multiply rounded-xl"></div>
                                            </div>
                                            <Link to={gContext.url(item.href)} className="opacity-0 hover:opacity-100 transition duration-300 ease-in-out relative px-4 py-20 sm:px-6 sm:py-24 lg:py-32 lg:px-8 flex items-center justify-center w-full">
                                                <div className="inline-flex items-center justify-center px-4 py-3 rounded-lg bg-black/50 text-white uppercase font-bold text-base min-h-[50px] w-full max-w-[200px]">{gContext.t('Leggi la news')}</div>
                                            </Link>
                                        </div>
                                        <p className="mt-2 inline truncate !leading-5 whitespace-normal line-clamp-2 text-sm sm:text-base text-ellipsis">{gContext.t(item.prev)}</p>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    <div className="w-full flex lg:hidden items-center justify-center">
                        <button type="button" className="transparent-button">{gContext.t('Tutte le news')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
