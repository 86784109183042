import * as React from "react";
import MainLayout from '../layouts/main';

import ChiSiamo from './home/chi-siamo';
import ProdottiList from './home/prodotti-list';
// import ContributiFiscali from './home/contributi-fiscali';
import News from "./home/news";
import SeoComponent from '../layouts/partials/SeoComponent'
import loadable from "@loadable/component";
const Partners = loadable(() => import('./home/partners'))
const HeroSwiper = loadable(() => import('./home/hero-swiper'))
const Help = loadable(() => import('./home/help'))

const IndexPage = ({ pageContext }) => {
    return (
        <MainLayout pageContext={pageContext} >
            <h1 className={'hidden'}>Tappezzeria Orsilli Pisa, schermature solari, tende da sole, pergole e pergole bioclimatiche</h1>
            <HeroSwiper/>
            <div className="relative overflow-hidden">
                <ChiSiamo/>
                <ProdottiList/>
                {/*<ContributiFiscali/>*/}
                <News/>
                <Help/>
                <Partners/>
            </div>
        </MainLayout>
    )
}

export default IndexPage
export const Head = ({pageContext}) => (
    <SeoComponent pageContext={pageContext} />
)