import * as React from 'react'
import {useContext} from "react";
import GlobalContext from "../../context/GlobalContext";
import {GatsbyImage} from "gatsby-plugin-image";
import {graphql, Link, useStaticQuery} from "gatsby";

export default function ChiSiamo() {
    const gContext = useContext(GlobalContext);
    const allImagesQuery = graphql`
            query {
              allFile(
                filter: {
                    relativeDirectory: { regex: "/(home)/" },
                    extension: {ne: "svg"}
                }
              ) {
                edges {
                  node {
                    base
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]       
                        placeholder: NONE        
                        quality: 100
                      )
                    }
                  }
                }
              }
            }
          `
    const {
        allFile: {edges: images},
    } = useStaticQuery(allImagesQuery)
    return (
        <div className="max-w-[1400px] mx-auto py-16 px-4 sm:px-6 lg:px-8 relative w-full grid grid-cols-1 lg:grid-cols-12 gap-6">
            <div className="relative z-10 col-start-1 row-start-1 col-span-3 ml-4 hidden lg:block">
                <div className="absolute inset-y-0 right-0 w-full max-w-[280px] h-[180px] shadow-mine bg-secondary rounded-3xl">
                    <div className="w-full h-full p-8 flex items-center justify-center">
                        <img className="w-full pointer-events-none" src={'/gibus-atelier.svg'} alt="Gibus" width="216" height="129"/>
                    </div>
                </div>
            </div>

            <div className="lg:col-span-9 lg:col-start-4">
                <h2 className="title-section text-center lg:text-right">{gContext.t('La nostra esperienza è garanzia di qualità')}</h2>
            </div>
            <div className="lg:col-span-7 lg:col-span-8">
                <div className="flex-1 relative mt-10 lg:mt-0">
                    <div className="absolute z-10 top-0 left-10 py-6 px-5 inline-block lg:hidden bg-secondary shadow-mine rounded-2xl transform -translate-y-10">
                        <img className="w-full max-w-[120px] sm:max-w-[230px] pointer-events-none" src={'/gibus-atelier.svg'} alt="Gibus" width="216" height="129"/>
                    </div>
                    <GatsbyImage image={gContext.getImageFromName(images, 'GIBUS_SPAZIO_7.jpg')}
                                 alt={'GIBUS_SPAZIO_7.jpg'}
                                 loading="eager"
                                 objectFit="cover"
                                 objectPosition="50% 50%"
                                 className="object-cover object-center rounded-3xl mx-auto w-full h-[40vh] sm:h-[500px]" />
                </div>
            </div>
            <div className="lg:col-span-5 lg:col-span-4 text-base sm:text-lg md:text-xl space-y-12 text-justify flex flex-col items-center lg:items-start leading-8 py-4 max-w-[410px]">
                <p dangerouslySetInnerHTML={{ __html: gContext.t('about.text.1') }}></p>
                <p dangerouslySetInnerHTML={{ __html: gContext.t('about.text.2') }}></p>

                <Link to={gContext.url('about')} className="primary-button w-full max-w-[250px] lg:!mt-auto">
                    {gContext.t('chi siamo')}
                </Link>
            </div>
        </div>
    )
}
